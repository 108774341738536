<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="permissions"
      :items-per-page="10"
      :search="search"
      :expanded.sync="expanded"
      show-expand
      item-key="permission_id"
      sort-by="first_name"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="page-title">
            <v-icon class="secondary--text" left light>{{ icons.mdiAccountKeyOutline }}</v-icon>
            {{ $route.meta.title }}</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-layout>
            <v-spacer></v-spacer>
            <div v-for="(permission, index) in permission" :key="index">
              <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                  <v-btn
                    color="accent"
                    v-show="user_submenu.create == 1 && permission.role_id == 3"
                    rounded
                    @click.prevent="$router.push({ name: 'add_permission' })"
                  >
                    <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                    <span>Create New</span>
                  </v-btn>
                </div>
              </div>
            </div>
            <div v-show="currentUser.role_id == 1">
              <v-btn color="accent" rounded @click.prevent="$router.push({ name: 'add_permission' })">
                <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                <span>Create New</span>
              </v-btn>
            </div>
          </v-layout>
        </v-toolbar>
        <v-divider class="mb-2"></v-divider>
        <v-text-field
          color="accent"
          class="mx-2"
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ formatDate(item.created_at) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click.prevent="
                $router.push({
                  name: 'edit_permission',
                  params: { id: item.permission_id },
                })
              "
              dark
              class="mx-1"
              fab
              x-small
            >
              <v-icon size="20">{{ icons.mdiPencilOutline }}</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="error" v-bind="attrs" v-on="on" @click.prevent="" dark class="mx-1" fab x-small>
              <v-icon size="20">{{ icons.mdiDeleteForeverOutline }}</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            :headers="menuHeaders"
            :items="item.usermenus"
            :expanded.sync="menuExpanded"
            item-key="user_menu_id"
            show-expand
            class="elevation-1"
            hide-default-footer
          >
            <template v-slot:item.menu_id="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:item.create="{ item }">
              <v-col cols="12" sm="12" md="12" class="mt-n4 ml-n4">
                <v-switch
                  v-model="item.create"
                  label="Create"
                  readonly
                  color="primary"
                  :value="1"
                  hide-details
                ></v-switch>
              </v-col>
            </template>
            <template v-slot:item.read="{ item }">
              <v-col cols="12" sm="12" md="12" class="mt-n4 ml-n4">
                <v-switch v-model="item.read" label="Read" readonly color="primary" :value="1" hide-details></v-switch>
              </v-col>
            </template>
            <template v-slot:item.update="{ item }">
              <v-col cols="12" sm="12" md="12" class="mt-n4 ml-n4">
                <v-switch
                  v-model="item.update"
                  label="Update"
                  readonly
                  color="primary"
                  :value="1"
                  hide-details
                ></v-switch>
              </v-col>
            </template>
            <template v-slot:item.delete="{ item }">
              <v-col cols="12" sm="12" md="12" class="mt-n4 ml-n4">
                <v-switch
                  v-model="item.delete"
                  label="Delete"
                  readonly
                  color="primary"
                  :value="1"
                  hide-details
                ></v-switch>
              </v-col>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>SubMenu</th>
                        <th>Create</th>
                        <th>Read</th>
                        <th>Update</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(submenu, index) in item.user_submenus" :key="index">
                        <td>
                          {{ submenu.name }}
                        </td>
                        <td>
                          <v-col cols="12" sm="12" md="12" class="mt-n4 ml-n4">
                            <v-switch
                              v-model="submenu.create"
                              label="Create"
                              readonly
                              color="primary"
                              :value="1"
                              hide-details
                            ></v-switch>
                          </v-col>
                        </td>
                        <td>
                          <v-col cols="12" sm="12" md="12" class="mt-n4 ml-n4">
                            <v-switch
                              v-model="submenu.read"
                              label="Read"
                              readonly
                              color="primary"
                              :value="1"
                              hide-details
                            ></v-switch>
                          </v-col>
                        </td>
                        <td>
                          <v-col cols="12" sm="12" md="12" class="mt-n4 ml-n4">
                            <v-switch
                              v-model="submenu.update"
                              label="Update"
                              readonly
                              color="primary"
                              :value="1"
                              hide-details
                            ></v-switch>
                          </v-col>
                        </td>
                        <td>
                          <v-col cols="12" sm="12" md="12" class="mt-n4 ml-n4">
                            <v-switch
                              v-model="submenu.delete"
                              label="Delete"
                              readonly
                              color="primary"
                              :value="1"
                              hide-details
                            ></v-switch>
                          </v-col>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
        </td>
      </template>
      <template v-slot:no-data>
        <v-btn color="accent" @click="getPermissions"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
  
  <script>
import {
  mdiAccountTie,
  mdiMagnify,
  mdiDotsVertical,
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteForeverOutline,
  mdiAccountCircle,
  mdiAccountKeyOutline,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  components: {},

  created: function () {
    this.getPermissions()
  },

  data() {
    return {
      expanded: [],
      menuExpanded: [],
      headers: [
        {
          text: 'Name',
          value: 'username',
        },
        {
          text: 'User Type',
          value: 'user_type',
        },
        {
          text: 'Email Address',
          value: 'email',
        },
        { text: 'Created At', value: 'created_at', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      menuHeaders: [
        {
          text: 'Menu',
          value: 'menu_id',
        },

        {
          text: 'Create',
          value: 'create',
        },
        {
          text: 'Read',
          value: 'read',
        },
        {
          text: 'Update',
          value: 'update',
        },
        {
          text: 'Delete',
          value: 'delete',
        },
      ],
      subHeaders: [
        {
          text: 'Name',
          value: 'submenu_id',
        },

        {
          text: 'Create',
          value: 'create',
        },
        {
          text: 'Read',
          value: 'read',
        },
        {
          text: 'Update',
          value: 'update',
        },
        {
          text: 'Delete',
          value: 'delete',
        },
      ],
      permissions: [],
      permission: {},
      search: '',
      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiAccountCircle,
        mdiAccountKeyOutline,
      },
    }
  },

  methods: {
    getPermissions: function () {
      this.$store
        .dispatch('permission/fetchPermissions')
        .then(response => {
          this.permissions = this.fetchPermissions
        })
        .catch(err => {
          console.log(err)
        })
    },

    getAuthorizations: function () {
      this.$store
        .dispatch('authorization/fetchModules', {
          user_id: this.currentUser.user_id,
          menu_id: 9,
          submenu_id: 14,
        })
        .then(response => {
          this.permission = this.fetchAuthorizations
        })
        .catch(err => {
          console.log(err)
        })
    },

    deleteUser: function (id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })

            this.$store
              .dispatch(`user/deleteUser`, { user_id: id })
              .then(response => {
                this.getUsers()
              })

              .catch(err => {
                console.log(err)
              })
          }
        })
    },

    formatDate(value) {
      return moment(value).format('l')
    },
  },

  computed: {
    ...mapGetters({
      fetchPermissions: 'permission/fetchPermissions',
      fetchAuthorizations: 'authorization/fetchAuthorizations',
    }),

    currentUser() {
      return this.$store.getters['authentication/currentUser']
    },
  },
}
</script>

